import axiosApi from '@/api/axiosApi';

export const exportExcel = () => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/training-course/exportExcel`,
        responseType: "blob",
    })
}

export const getCourses = () => {
    return axiosApi({
        method: 'GET',
        url: '/intserv/training-course/getCourses'
    })
}