import axiosApi from "./axiosApi"

export const getAllWiki = () => {
    return axiosApi({
        method: 'get',
        url: 'wiki/wiki/getAllWiki'
    })
}

export const getAllWikiFilter = () => {
    return axiosApi({
        method: 'get',
        url: 'wiki/wiki/getAllWikiFilter'
    })
}

export const getWikiContentByTitle = (data) => {
    return axiosApi({
        method: 'post',
        data,
        url: 'wiki/wiki/getWikiContentByTitle'
    })
}

export const searchWikiContent = (data) => {
    return axiosApi({
        method: 'post',
        data,
        url: 'wiki/wiki/searchWikiContent'
    })
}

export const addPv = (data) => {
    return axiosApi({
        method: 'post',
        data,
        url: 'wiki/wiki/addPv'
    })
}

export const getPvByWikiId = (data) => {
    return axiosApi({
        method: 'post',
        data,
        url: 'wiki/wiki/getPvByWikiId'
    })
}