<template>
  <div class="t-page">
        <div class="course">
            <el-button type="primary" style="position: absolute; right: 0; top: 70px;" @click="getExcel">Download Excel</el-button>
            <div class="t-table" v-html="content"></div>
        </div>
        <!-- <div class="border"></div>
        <div class="title">HOLOWITS PARTNERS TRAINING CATALOG</div>
        <el-table :data="tableData" border fit row-key="id" ref="tableList" style="width: 100%;">
            <el-table-column label="No." prop="no" width="50"></el-table-column>
            <el-table-column label="Category" prop="category" width="120"></el-table-column>
            <el-table-column label="Content" prop="content" width="300"></el-table-column>
            <el-table-column label="Material Source" prop="materialSource"></el-table-column>
            <el-table-column label="Material Link" prop="materialLink"></el-table-column>
        </el-table> -->
  </div>
</template>

<script>
import { exportExcel, getCourses } from "@/api/trainingCourseApi.js"
import { getWikiContentByTitle } from "@/api/wikiApi"
export default {
name: "TrainingCourseIndex",
data() {
    return {
        tableData: [],
        content: null
    }
},
mounted() {
    // this.getExcel()
    // this.getData()
    this.getWikiContentByTitle()
},
methods: {
    async getExcel() {
        const res = await exportExcel();
        const blob = new Blob([res.data], {type: res.data.type})
        if (blob.size !== 0) {
            let tag = document.createElement('a');
            tag.href = URL.createObjectURL(blob);
            tag.download = res.headers['content-disposition'].split('=')[1];
            tag.click();
            URL.revokeObjectURL(tag.href)
        }
        // console.log(res)
    },
    async getWikiContentByTitle() {
        const {data: {isSuccess, data}} = await getWikiContentByTitle({
            title: `HOLOWITS Training Tutorials - L3`
        })
        if (isSuccess) {
            this.content = data.content;
        }
    },
    async getData() {
        const {data: {data, isSuccess}} = await getCourses()
        if (isSuccess) {
            console.log(data)
            this.tableData = data;
        }
    }
}
}
</script>

<style lang="less" scoped>
.t-page {
    height: inherit;
    .course {
        position: relative;
        width: 1644px;
        margin: 0 auto;
        padding: 50px 0;
        .border {
            height: 12px;
            border-top: 1px solid rgb(199, 0, 11);
            border-bottom: 1px solid rgb(199, 0, 11);
        }
        .title {
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            padding: 20px 0;
        }
        /deep/ table {
            width: 100% !important;
        }
        /deep/ a {
            color: #0000ff !important;
        }
        /deep/ table {
            td, th {
                padding: 0.4rem;
            }
        }
        /deep/ p, span, a {
            line-height: 3;
            font-size: 16px;
        }
    }
}
</style>